import React, { useState, useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"

// IMPORTANT: Update this with your Cloudflare Worker URL
const WORKER_URL = "https://shy-block-d95e.grantwang31.workers.dev"

export default function SecurePageProtection(props) {
    const { pageName, children } = props
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    // Authentication endpoints
    const authEndpoint = `${WORKER_URL}/authenticate`
    const validateEndpoint = `${WORKER_URL}/validate`

    // Check existing token on component mount
    useEffect(() => {
        const checkAuth = async () => {
            const token = sessionStorage.getItem(`auth_token_${pageName}`)

            if (token) {
                try {
                    const response = await fetch(validateEndpoint, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ token }),
                    })

                    const data = await response.json()
                    if (data.valid) {
                        setIsAuthenticated(true)
                    }
                } catch (err) {
                    console.error("Token validation error:", err)
                }
            }

            setIsLoading(false)
        }

        checkAuth()
    }, [pageName, validateEndpoint])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")

        try {
            const response = await fetch(authEndpoint, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ page: pageName, password }),
            })

            const data = await response.json()

            if (data.success) {
                // Store token in session storage
                sessionStorage.setItem(`auth_token_${pageName}`, data.token)
                setIsAuthenticated(true)
            } else {
                setError(data.message || "Invalid password")
            }
        } catch (err) {
            setError("Authentication failed. Please try again.")
        }
    }

    if (isLoading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                Loading...
            </div>
        )
    }

    if (isAuthenticated) {
        return <>{children}</>
    }

    return (
        <div
            style={{
                maxWidth: "400px",
                margin: "100px auto",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                backgroundColor: "white",
            }}
        >
            <h2 style={{ marginBottom: "20px" }}>Protected Content</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password"
                    style={{
                        width: "100%",
                        padding: "10px",
                        marginBottom: "15px",
                        borderRadius: "4px",
                        border: "1px solid #ddd",
                    }}
                />
                {error && (
                    <p style={{ color: "red", marginBottom: "15px" }}>
                        {error}
                    </p>
                )}
                <button
                    type="submit"
                    style={{
                        width: "100%",
                        padding: "10px",
                        backgroundColor: "#000",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                    }}
                >
                    Submit
                </button>
            </form>
        </div>
    )
}

// Add property controls for use in Framer
SecurePageProtection.propertyControls = {
    pageName: {
        type: ControlType.String,
        title: "Page Name",
        defaultValue: "project1",
    },
}
